import axios from "axios";
import React, { createContext, useContext, useState, useRef} from "react";
import { useReducer } from "react";
import _static from "../config/static";

const AuthContext = createContext(null);
const initialState = {
  user: null,
  isLoading: false,
  isAuthenticated: false,
}

const AuthReducer = (state, action) => {
  switch(action.type) {
      case 'CURRENT_USER_UPDATE':
          return {
              ...state,
              user: action.user,
              isAuthenticated: action.isAuthenticated
          }
      case 'LOGOUT_SUCCESS': 
          return initialState
      case 'START_LOADER':
          return {
              ...state,
              isLoading: true
          }
      case 'STOP_LOADER':
          return {
              ...state,
              isLoading: false
          }
  }
}

const useAuthReducer = () => {
  return useReducer(AuthReducer, initialState)
}


export const AuthProvider = ({ children }) => {
  const { auth_Url } = _static;
  const [state, dispatch] = useAuthReducer()
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [playlistRename, setPlaylistRename] = useState("");
  const [notify, setNotify] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [shareRecording, setShareRecording] = useState(false);
  const [activeNoti, setActiveNoti] = useState();
  const [subNo,setSubNo]=useState()

  const ref = useRef(null);
 
  const getCurrentUser = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${auth_Url}/auth/api/getcurrentuser`, {
        method: "GET",
        credentials: "include",
      });

      const result = await response.json();

      if (response.ok) {
        setUser(result?.user);
        setLoading(false);
      } else {
        setUser(null);
        setLoading(false);
      }
    } catch (err) {
      setUser(null);
      console.log(err);
      setLoading(false);
    }
  };

  async function saveVideo(url, item, trim) {
    setActiveNoti("Download");
    let response = await axios({
      url: url,
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        setProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      },
    });
    let blobUrl = URL.createObjectURL(response.data);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = blobUrl;
    a.target = "_blank";
    a.download = item?.videoName || item?.id
    a.click();
    setTimeout(() => {
      setProgress(0);
      setActiveNoti("");
    }, 1000);
  }

  return (
    <AuthContext.Provider
      value={{
        authState: state,
        authDispatch: dispatch,
        shareRecording,
        setShareRecording,
        user,
        setPlaylistRename,
        playlistRename,
        saveVideo,
        progress,
        getCurrentUser,
        loading,
        notify,
        setNotify,
        notificationText,
        setNotificationText,
        activeNoti,
        setActiveNoti,
        ref,
        subNo,setSubNo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
