import React, { useEffect, useState } from "react";
import {
  ButtonsWrap,
  DownloadBtn,
  LoaderWrap,
  LoadVideo,
  PlayerWrap,
  PreviewBox,
  PreviewBoxHead,
  TrimBtn,
  VidedoPreviewWrap,
} from "../presentation-styled";
import Close from "../Images/Close.svg";
import Load from "../Images/loadBoy.gif";
import Trim from "../Images/trim.svg";
import { v4 as uuidv4 } from "uuid";
import PlayerIO from "../../Dashboard/CaptureView/CapturePlayer/plyrio";
import getBlobDuration from "get-blob-duration";
import { useDashAuth } from "../../../context/dashboard/dashAuth";
import { useAuth } from "../../../context/auth";
import { useNavigate } from "react-router-dom";
import _static from "../../../config/static";
const VidedoPresentationPreview = ({ setPreviewSec, blobItem, pptName, setBlobItem }) => {
  const fileUuid = uuidv4();
  const [loading, setLoading] = useState(false);
  const [videoData, setVideoData] = useState()
  const [linkUrl, setLinkUrl] = useState(false);
  const { edit_Url, app_Url } = _static
  const dash = useDashAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (blobItem) {
      uploadRec()
    }
    return () => {
      setVideoData()
      setLoading(false)
      setBlobItem()
    };
  }, [blobItem]);
  const uploadRec = async () => {
    let totalDuration;
    let durBlob;
    const fileName = `Flonnect_${new Date().toJSON().slice(0, 10)}_${fileUuid}`;
    const file = new File([blobItem], `${fileName}.mp4`, { type: "video/mp4" });
    const name = pptName;
    const blobUrl = URL.createObjectURL(blobItem);
    await getBlobDuration(blobUrl).then(async function (duration) {
      totalDuration = duration * 1000;
      let d = Number(Math.floor(duration));
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      var hour = h < 10 ? `0${h}` : h;
      var minute = m < 10 ? `0${m}` : m;
      var second = s < 10 ? `0${s}` : s;
      durBlob = hour + ":" + minute + ":" + second;
      let recordType = "PRESENTATION"
      try {
        const data = await dash.uploadLocalVideo(
          file,
          fileName,
          totalDuration,
          durBlob,
          name,
          recordType,
          ''
        );
        setLoading(true);
        setVideoData(data?.video);
      } catch (error) {
        console.error("Error uploading video:", error);
      }
    }).catch((err) => {
      console.log(err)
    });
  };
  const auth = useAuth()
  useEffect(() => {
    let intervalId; // Declare interval ID

    if (videoData) {
      intervalId = setInterval(() => {
        checkPro(videoData?.linkUrl, intervalId); // Pass intervalId for clearing
      }, 2000);
    }

    // Cleanup on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [videoData]);
  const checkPro = async (url, intervalId) => {
    await fetch(`https://dfw25j7e2vqby.cloudfront.net/${auth?.authState?.user?.email?.split("@")[0]}/${videoData?.linkUrl?.split("/")[3]?.split(".")[0]
      }`).then((response) => {
        if (response.status === 200) {
          setLinkUrl(true)
          clearInterval(intervalId)
        }
      })
  }
  return (
    <VidedoPreviewWrap>
      <PreviewBox linkUrl={linkUrl} >
        <PreviewBoxHead>
          <div>Video Preview</div>
          <img
            src={Close}
            onClick={() => {
              setPreviewSec(false);
              setBlobItem();
            }}
          />
        </PreviewBoxHead>
        {loading ? linkUrl ? (
          <PlayerWrap>
            <PlayerIO videoSuccess={true} videoData={videoData} userName={auth?.authState?.user?.email?.split("@")[0]} presentation={true} />
          </PlayerWrap>
        ) : <LoadVideo>
          <LoaderWrap>
            <div>Waite a sec We are Processing your Recording...</div>
            <div>Please don't close the window...</div>

            <img src={Load} draggable="false" />
          </LoaderWrap>
        </LoadVideo> : (
          <LoadVideo>
            <LoaderWrap>
              <div>Waite a sec We are Loading your Recording...</div>
              <div>Please don't close the window...</div>

              <img src={Load} draggable="false" />
            </LoaderWrap>
          </LoadVideo>
        )}
        {loading && linkUrl ? (
          <ButtonsWrap>
            <TrimBtn onClick={() => {
              if (auth?.authState?.user?.active) {
                window.open(`${edit_Url}/${videoData?.id}`);
              } else {
                window.open(`${app_Url}/dashboard/payment`);
              }
            }}>
              <img src={Trim} />
              Trim
            </TrimBtn>
            <DownloadBtn onClick={() => {
              window.open(`${app_Url}/dashboard/library/video/${videoData?.id}`)
            }}>Manage</DownloadBtn>
          </ButtonsWrap>
        ) : (
          ""
        )}
      </PreviewBox>
    </VidedoPreviewWrap>
  );
};

export default VidedoPresentationPreview;
