import React, {
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from "react";
import Plyr from "plyr-react";
import { useCapture } from "../Context/captureContext";
import { PlyrVideo } from "../../VideoPlayer/VideoPlayer.styled";
import "plyr-react/plyr.css";
import "./plyr.css";
import { useLocation } from "react-router-dom";
import commentIcon from "../../../../assets/img/comment_icon.svg";
import addEmojiIcon from "../../../../assets/img/add-emoji.svg";
import addCommentsIcon from "../../../../assets/img/add-comment.svg";
import { useAuth } from "../../../../context/auth";
import { useQuery, useMutation } from "react-query";
import deleteImage from "../../../../assets/img/delete.svg";
import { v4 as uuidv4 } from "uuid";
import {
  EmojiAndComentsContainer,
  EmojiAndComentsWrapper,
  VideoInsideEmojiContainer,
  VideoInsdieEmojiSubContainer,
  CommonlyUsedEmojis,
  AddReactions,
  CommnetInputContainer,
  CommentForm,
  CancelButton,
  CommentContainer,
  ComementUserIcon,
  Comment,
  EmojiOrCommentIconWrapper,
  EmojiNameContainer,
  EmojiAndComentsWrapperOutsideVideo,
  EmojiAndComentsContainerOutsideVideo,
  CommnetInputContainerOutsideVideo,
  DeleteIcon,
  FlonnectBanner
} from "./plyr.styled";
import { timeToSeconds } from "../../../../utils/utils";
import CustomProgressBar from "./CustomProgressBar/CustomProgressBar";
import { getVideoTimeReactions, addTimeLineReactions, deletTimeLineReaction, commonlyUsedeEmojis, convertTime, truncateText, fetchCaptions} from "./utils";



const VideoComponent = forwardRef((props, ref) => {
  const { videoData, videoSuccess, userName } = props.data;
  const location = useLocation();
  const {
    data: captionBlobUrl,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['captions', videoData?.subtitleUrl],
    queryFn: () => fetchCaptions(videoData?.subtitleUrl),
    enabled: !!videoData?.subtitleUrl,
    staleTime: 1000 * 60 * 5, 
    cacheTime: 1000 * 60 * 30, 
    retry: 2, 
    onError: (error) => {
      console.error('Error fetching captions:', error);
    },
  });
  const src =
    location?.pathname?.includes("library") ||
    location?.pathname?.includes("edit/video")|| location?.pathname?.includes('dashboard/presentation')
      ? videoSuccess
        ? videoData?.linkUrl?.includes("video-sink-optimised")
          ? videoData.linkUrl?.replace(
              "https://video-sink-optimised.s3.ap-south-1.amazonaws.com",
              "https://dfw25j7e2vqby.cloudfront.net"
            )
          : `https://dfw25j7e2vqby.cloudfront.net/${userName}/${
              videoData?.linkUrl?.split("/")[3]?.split(".")[0]
            }`
        : videoData?.linkUrl
      : location?.pathname?.includes("workspace")
      ? videoData?.linkUrl?.includes("video-sink-optimised")
        ? videoData.linkUrl?.replace(
            "https://video-sink-optimised.s3.ap-south-1.amazonaws.com",
            "https://dfw25j7e2vqby.cloudfront.net"
          )
        : `https://dfw25j7e2vqby.cloudfront.net/${userName}/${
            videoData?.linkUrl?.split("/")[3]?.split(".")[0]
          }`
      : videoData?.videoUrl;
  const videoSrc = {
    type: "video",
    // poster={videoData?.}
    sources: [
      {
        src: src,
        type: "video/mp4",
        size: 720,
      },
    ],
    tracks: captionBlobUrl ? [
      {
        kind: "captions",
        label: "English",
        src: captionBlobUrl,
        srclang: "en",
        default: true
      }
    ] : []
  };

  const options = {
    controls: [
      "progress",
      "play-large",
      "play",
      "mute",
      "volume",
      "current-time",
      "duration",
      "settings",
      "fullscreen",
    ],
    fullscreen: { enabled: true, fallback: true, iosNative: true },
    smoothPlayBar: true,

    speed: {
      options: [0.5, 0.75, 1, 1.25, 1.5, 2], // Specify desired speed options
    },
    renderCaptionsNatively: false,
    smoothScrubbing: false,
    captions: {
      active: true,
      language: 'auto',
      update: true
    },
    keyboard: {
      focused: true,
      global: true
    }
  };

  useEffect(() => {
    return () => {
      if (captionBlobUrl) {
        URL.revokeObjectURL(captionBlobUrl);
      }
    };
  }, [captionBlobUrl]);
  return (
    <>
      <Plyr options={options} source={videoSrc} ref={ref} />
    </>
  );
});

const isMemoize = (prev, next) => {
  if (prev?.data?.videoData?.id !== next?.data?.videoData?.id) return false;
  return true;
};
const MemoizedVideoComponent = memo(VideoComponent);

const PlayerIO = memo((props) => {
  const auth = useAuth();
  const [totalTime, setTotalTime] = useState(0);
  const [showReactions, setShowReactions] = useState(true);
  const [contentElements, setContentElements] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [isComment, setIsComment] = useState({
    insideVideo: false,
    outsideVideo: false,
  });
  const [commentValue, setCommentValue] = useState("");
  const [currentTime, setCureentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [customPause, setCustomPause] = useState(false);
  const videoId = props?.videoData?.id;
  const videoLocation = useLocation();
  const contentRef = useRef(null);

  const insideInputRef = useRef(null);
  const outsideInputRef = useRef(null);
  const emojiAndcommnetsWrappper = useRef(null);
  const plyrLocalRef = useRef(null);
  const ref = videoLocation.pathname.includes("workspace")
    ? plyrLocalRef
    : auth?.ref;
  const { data, isLoading, error } = useQuery(
    ["getVideoTimeLineReactions", videoId, videoLocation],
    () => getVideoTimeReactions(videoId, videoLocation)
  );
  const { mutate: postTimeLineReactions } = useMutation(addTimeLineReactions, {
    onSuccess: (data, variables) => {
      variables.newReactionData.profileImage =
        auth?.authState?.user?.profileImage;
      setContentElements([...contentElements, variables?.newReactionData]);
    },
  });
  const { mutate: deleteReaction } = useMutation(deletTimeLineReaction, {
    onSuccess: (data, variables) => {
      const updatedItems = contentElements.filter(
        ({ interactionId }) => interactionId !== variables?.interactionId
      );
      setContentElements(updatedItems);
    },
  });



  const plyrReady = () => {
    const player = ref?.current?.plyr;

    if (player) {
      const totalTime = player.duration;
      setTotalTime(totalTime);

      const playerControls = player.elements.controls;

      const playerProgressContainer = player.elements.controls?.querySelector(
        ".plyr__progress__container"
      );

      if (playerProgressContainer && playerControls) {
        playerControls?.appendChild(emojiAndcommnetsWrappper?.current);
        playerProgressContainer?.appendChild(contentRef?.current);
      }

      const handleTimeUpdate = () => {
        setCureentTime(player.currentTime);

        const pauseClass = document.querySelector(".plyr--paused");
        const hideControlsClass = document.querySelector(
          ".plyr--hide-controls"
        );

        if (pauseClass || !hideControlsClass) {
          setShowReactions(true);
        } else {
          setShowReactions(false);
        }
      };

      const handleExitFullscreen = () => {
        setIsComment((prevState) => ({ ...prevState, insideVideo: false }));
        setFullScreen(false);
        setShowEmojiPicker(false);
      };

      const handleFullscreenChange = (event) => {
        setFullScreen(true);
      };

      const handlePlaying = () => {
        setIsPlaying(true);
      };
      const handlePause = () => {
        setIsPlaying(false);
      };

      player.on("enterfullscreen", handleFullscreenChange);

      player.on("exitfullscreen", handleExitFullscreen);

      player.on("timeupdate", handleTimeUpdate);

      player.on("play", handlePlaying);

      player.on("pause", handlePause);

      return () => {
        player.off("timeupdate", handleTimeUpdate);
        player.off("enterfullscreen", handleFullscreenChange);
        player.off("exitfullscreen", handleExitFullscreen);
        player.off("play", handlePlaying);
        player.off("pause", handlePause);
      };
    }
  };

  const handleEmojiClick = (emojiName) => {
    const newReactionData = {
      type: "emoji",
      videoTime: currentTime.toString(),
      interaction: emojiName,
      ...(!videoLocation?.pathname?.includes("capture")
        ? { videoId: videoId }
        : { docId: videoId }),
      interactionId: uuidv4(),
    };
    postTimeLineReactions({ newReactionData, videoLocation });
    // setShowEmojiPicker(false);
  };

  const handleComment = (type) => {
    setIsComment((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
    // setFullScreen(!fullScreen)
  };

  const handleSubmitComment = (type) => {
    if (commentValue === "") return;
    const newReactionData = {
      type: "comment",
      videoTime: currentTime.toString(),
      interaction: commentValue,
      ...(!videoLocation?.pathname?.includes("capture")
        ? { videoId: videoId }
        : { docId: videoId }),
      interactionId: uuidv4(),
    };

    postTimeLineReactions({ newReactionData, videoLocation });
    setCommentValue("");
    setIsComment((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const handleDeletReaction = (interactionId) => {
    deleteReaction({ interactionId, videoLocation });
  };

 

  useEffect(() => {
    setTimeout(plyrReady, 1000);
  }, [props]);

  useEffect(() => {
    const reactionData = !videoLocation.pathname.includes("capture")
      ? data?.videoTimelineInteractions
      : data?.data?.videoTimelineInteractions;
    setContentElements(reactionData);
  }, [data]);

  useEffect(() => {
    const player = ref?.current?.plyr;
    if (isComment.insideVideo) {
      insideInputRef?.current?.focus();
    } else if (isComment.outsideVideo) {
      outsideInputRef?.current?.focus();
    }
    if (
      (isComment.insideVideo || isComment.outsideVideo) &&
      player?.play &&
      isPlaying
    ) {
      player.pause();
      setCustomPause(true);
    } else if (
      (!isComment.insideVideo || !isComment.outsideVideo) &&
      player?.pause &&
      !isPlaying &&
      customPause
    ) {
      player.play();
      setCustomPause(false);
    }
  }, [isComment]);

  return (
    <>
      <PlyrVideo>
        <MemoizedVideoComponent data={props} ref={ref} />
        {/* {ref?.current?.plyr?.elements?.progress && ( */}
        <CustomProgressBar player={ref?.current?.plyr}  chapters={props?.transcriptChapters} />
      {/* )} */}
        
   

        <VideoInsideEmojiContainer className="content" ref={contentRef}>
          {(contentElements?.length > 0 && !props?.presentation) &&
            contentElements.map((element, index) => {
              return (
                <VideoInsdieEmojiSubContainer
                  key={element.id}
                  element={element}
                  currentTime={currentTime}
                  totalTime={totalTime}
                  showReactions={showReactions}
                >
                  {totalTime !== 0 && (
                    <EmojiOrCommentIconWrapper elementType={element.type}>
                      <img
                        src={
                          element.type === "emoji"
                            ? commonlyUsedeEmojis?.find(
                                (emoji) => emoji.name === element?.interaction
                              )?.emojiUrl
                            : commentIcon
                        }
                        alt={
                          element.type === "emoji" ? "emoji" : "comment-icon"
                        }
                      />
                    </EmojiOrCommentIconWrapper>
                  )}
                  {element.type === "emoji" && (
                    <EmojiNameContainer
                      elementTime={element?.videoTime}
                      totalTime={totalTime}
                    >
                      <div>{element?.interaction}</div>
                      <DeleteIcon
                        onClick={() =>
                          handleDeletReaction(element?.interactionId)
                        }
                      >
                        <img src={deleteImage} alt="delete" />
                      </DeleteIcon>
                    </EmojiNameContainer>
                  )}

                  {element.type === "comment" && (
                    <CommentContainer
                      currentTime={currentTime}
                      elementTime={element?.videoTime}
                      totalTime={totalTime}
                    >
                      <ComementUserIcon
                        userData={auth?.authState}
                        element={element}
                      >
                        {element?.profileImage ? (
                          <img src={element?.profileImage} alt="user-icon" />
                        ) : (
                          <div>
                            {!element?.email
                              ? auth?.authState?.user?.username
                                  ?.charAt(0)
                                  ?.toUpperCase()
                              : element?.email?.charAt(0)?.toUpperCase()}
                          </div>
                        )}
                      </ComementUserIcon>
                      <Comment>
                        {truncateText(element?.interaction, 30)}
                      </Comment>
                      <DeleteIcon
                        onClick={() =>
                          handleDeletReaction(element?.interactionId)
                        }
                      >
                        <img src={deleteImage} alt="delete" />
                      </DeleteIcon>
                    </CommentContainer>
                  )}
                </VideoInsdieEmojiSubContainer>
              );
            })}
        </VideoInsideEmojiContainer>

    
        <EmojiAndComentsContainer
          ref={emojiAndcommnetsWrappper}
          isComment={isComment.insideVideo}
        >
          {fullScreen && !isComment.insideVideo &&  !props.presentation && (
            <EmojiAndComentsWrapper>
              <CommonlyUsedEmojis>
                {commonlyUsedeEmojis.map(({ name, emoji, emojiUrl }) => (
                  // <img key={name} src={emojiUrl} onClick={() => handleEmojiClick(name)} />
                  <div key={name} onClick={() => handleEmojiClick(name)}>
                    {emoji}
                  </div>
                ))}
              </CommonlyUsedEmojis>
              <AddReactions>
                {/* <div onClick={() => setShowEmojiPicker(!showEmojiPicker)} ><img src={addEmojiIcon} alt='add-emoji' /></div> */}
                <div onClick={() => handleComment("insideVideo")}>
                  <img src={addCommentsIcon} alt="add-comments" />
                </div>
              </AddReactions>
            </EmojiAndComentsWrapper>
          )}

          {isComment.insideVideo && (
            <CommnetInputContainer>
              <CommentForm videoPath={videoLocation.pathname} element="inside">
                <input
                  ref={insideInputRef}
                  type="text"
                  placeholder={`Comment @ ${convertTime(currentTime)}`}
                  value={commentValue}
                  onChange={(e) => setCommentValue(e.target.value)}
                />
                <div onClick={() => handleSubmitComment("insideVideo")}>
                  Comment
                </div>
              </CommentForm>
              <CancelButton
                onClick={() => handleComment("insideVideo")}
                videoPath={videoLocation?.pathname}
                element={"inside"}
              >
                <div>Cancel</div>
                <img src={deleteImage} alt="delete-icon" />
              </CancelButton>
            </CommnetInputContainer>
          )}
        </EmojiAndComentsContainer>
      </PlyrVideo>

      <EmojiAndComentsContainerOutsideVideo
        videoPath={videoLocation?.pathname}
        isComment={isComment.outsideVideo}
      >
        {!isComment.outsideVideo &&  !props?.presentation&& (
          <EmojiAndComentsWrapperOutsideVideo>
            <CommonlyUsedEmojis>
              {commonlyUsedeEmojis.map(({ name, emoji, emojiUrl }) => (
                // <img key={name} src={emojiUrl} onClick={() => handleEmojiClick(name)} />
                <div key={name} onClick={() => handleEmojiClick(name)}>
                  {emoji}
                </div>
              ))}
            </CommonlyUsedEmojis>
            <AddReactions>
              <div onClick={() => handleComment("outsideVideo")}>
                <img src={addCommentsIcon} alt="add-comments" />
              </div>
            </AddReactions>
          </EmojiAndComentsWrapperOutsideVideo>
        )}
        {isComment.outsideVideo && (
          <CommnetInputContainerOutsideVideo>
            <CommentForm element="outside" videoPath={videoLocation?.pathname}>
              <input
                ref={outsideInputRef}
                type="text"
                placeholder={`Comment @ ${convertTime(currentTime)}`}
                value={commentValue}
                onChange={(e) => setCommentValue(e.target.value)}
              />
              <div onClick={() => handleSubmitComment("outsideVideo")}>
                Comment
              </div>
            </CommentForm>
            <CancelButton
              onClick={() => handleComment("outsideVideo")}
              videoPath={videoLocation?.pathname}
              element={"outside"}
            >
              <div>Cancel</div>
              <img src={deleteImage} alt="delete-icon" />
            </CancelButton>
          </CommnetInputContainerOutsideVideo>
        )}
      </EmojiAndComentsContainerOutsideVideo>
    </>
  );
});

export default PlayerIO;
