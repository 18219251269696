import React, { useRef } from "react";
import {
  BrowseBtn,
  DragText,
  FileDRagDropWrap,
  FormatTextWrap,
  FoundIssueBtn,
  Loadimg,
  UploadPptComptWrap,
  UploadPptHeadWrap,
  Uploading,
} from "../presentation-styled";
import Load from "../Images/load.png";
import Upload from "../Images/backup.svg";
import Bug from "../Images/bug.png";
import Tip from "../Images/tip.png";
import { useAuth } from "../../../context/auth";
import _static from "../../../config/static";
import { useState } from "react";
import { replaceSpacesInFilename } from "../../../utils/utils";
import { Recorder } from "../../../components/Sidebar/Sidebar.styled";
import WebLogo from "../../../components/Navbar/images/WebLogo.svg";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { usePresentation } from "../context/presentationContext";

const UploadPptCompt = ({
  setPres,
  setImg,
  setFileName,
}) => {
  const inpRef = useRef(null);
  const auth = useAuth();
  const [load, setLoad] = useState(false);
  const { authState } = auth;
  const { capt_Url, aws_Url } = _static;
  let userName = authState?.user?.email?.split("@")[0];
  const navigate = useNavigate();
  const { presState, presDispatch } = usePresentation();
  const { tipsPopup, feedbackPopup } = presState
  const handleClick = () => {
    inpRef.current.click();
  };
  const handlePPTtoImages = async (apiObj) => {
    try {
      const response = await fetch(`${aws_Url}/revamp/extract-image-from-ppt`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(apiObj),
      });

      // Check if response is OK
      if (!response.ok) {
        setLoad(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        setLoad(false);
        const result = await response.json();
        setImg(result?.s3Url);
      }
    } catch (err) {
      // setError(err.message);
    } finally {
      // setLoading(false);
    }
  };
  const handleFileUpload = async (file) => {
    setLoad(true);
    if (file) {
      let currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000);
      const requestObject = {
        method: "post",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          fileName: `${userName}/presentation/${currentTimestampInSeconds}_${replaceSpacesInFilename(
            file?.name,
            "-"
          )}`,
          fileType: file.type,
        }),
      };
      await fetch(`http://localhost:5001/api/capture/aws/getpresignedurl`, requestObject)
        .then((response) => response.json())
        .then((url) => {
          if (url.signedUrl) {
            fetch(url.signedUrl, {
              method: "put",
              body: file,
              headers: {
                "Access-Control-Allow-Origin": "*",
              },
            }).then(async (re) => {
              setFileName(file?.name);
              handlePPTtoImages({
                fileUrl: `https://flonnect-capture-pdf.s3.ap-south-1.amazonaws.com/${userName}/presentation/${currentTimestampInSeconds}_${replaceSpacesInFilename(
                  file.name,
                  "-"
                )}`,
                userName,
              });
            });
          }
        });
    }
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const droppedFiles = Array.from(event.dataTransfer.files);
    handleFileUpload(droppedFiles[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        overflowY: "auto",
        position: "relative",
      }}
    >
      <UploadPptComptWrap onDrop={handleDrop} onDragOver={handleDragOver}>
        {/* <img src={Close} className="close-img" /> */}
        <UploadPptHeadWrap>
          <div>Upload Slides to Start Recording your Presentation!</div>
        </UploadPptHeadWrap>
        {!load ? (
          <FileDRagDropWrap onClick={handleClick}>
            <img src={Upload} />
            <DragText>Drag and Drop your file here or </DragText>
            <BrowseBtn>Browse to Upload</BrowseBtn>
            <input
              type="file"
              ref={inpRef}
              accept=".ppt,.pptx"
              onChange={async (e) => {
                if (e.target.files[0]) {
                  handleFileUpload(e.target.files[0]);
                }
              }}
            />
          </FileDRagDropWrap>
        ) : (
          <FileDRagDropWrap>
            <Loadimg src={Load} />
            <Uploading>Uploading your File....</Uploading>
          </FileDRagDropWrap>
        )}
        <FormatTextWrap>
          <div>
            You can upload your presentation slides in the following formats:
            PPT
          </div>
        </FormatTextWrap>
        {/* <FoundIssueBtn className="uplod-sec" onClick={() => {
          presDispatch({ type: "HANDLE_FEEDBACK_POPUP", data: "upload" })
        }}>
          <img draggable={false} src={Bug} /> Feedback/Issue
        </FoundIssueBtn>
        <FoundIssueBtn
          className="tip-sec"
          onClick={() => {
            presDispatch({ type: "HANDLE_TIPS_POPUP", data: "upload" })
            mixpanel.track("Tips clicked", {
              name: auth?.authState?.user?.username,
              email: auth?.authState?.user?.email,
              from: 'upload'
            });
          }}
        >
          <img draggable={false} src={Tip} /> Tips for Recording
        </FoundIssueBtn> */}
      </UploadPptComptWrap>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "5px",
        }}
      ></div>
    </div>
  );
};

export default UploadPptCompt;
